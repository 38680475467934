<template>
  <div class="aboutUsBox">
    <NavBar title="关于我们"></NavBar>
    <div class="contentBox">
      <Space :height="78"></Space>
      <div class="dfjcc">
        <van-image
          :width="parseInt($pxToPxRatio(214), 10)"
          :height="parseInt($pxToPxRatio(227), 10)"
          lazy-load
          fit="cover"
          :src="require('@/assets/images/other/other2.png')"
        />
      </div>
      <Space :height="76"></Space>
      <TextBox color="intro1" :font-size="26">
        HY立足中原，积极响应国家新基建号召，紧随国家战略，把区块链分布式数据存储赛道作为公司重点发展业务板块，为客户提供集群架构、数据中心部署，分布式存储、大规模集群运维、边缘计算等一站式解决方案。
      </TextBox>
      <Space :height="30"></Space>
      <TextBox color="title">优势</TextBox>
      <Space :height="30"></Space>
      <TextBox color="intro1" :font-size="26">
        一、HY依托开源技术IPFS分布式协议，在数据大爆炸趋势下通过IPFS底层互联网协议，2年内在全国各大城市建设30PB到100PB的小型数据库，为即将到来的5G，人工智能，物联网、边缘计算、公有云等业务提供基础设施保障。<br />
        <Space :height="10"></Space>
        二、HY在全国四个城市部署了4千多台存储服务器设备，总存储容量空间500PB，承接来自全球的数据存储量目前以每日500TB到1.5PB增长，整体规模在IPFS分布式存储领域位列全球前三名，后续会持续投入高额资金用于技术研发。公司目前在职高等技术人才60名，已经取得了该领域国家软件著作专利8项。<br />
        <Space :height="10"></Space>
        三、HY和阿里云已达成框架合作协议，高性能计算，分布式存储，网络加速，数据安全，数据分发等各个领域已达成框架合作，尤其是在分布式存储领域，此外HY和阿里云、中科曙光、景安等国内互联网行业及存储巨头都在展开合作。共同实现数据价值的最大化利用，携手探索分布式存储领域中更多的发展方向与落地应用，推动Web3.0的到来。为客户提供安全高效的数据存储备份、网络安全、云计算、大数据等方面的整体解决方案。<br />
      </TextBox>
      <Space :height="67"></Space>
    </div>
  </div>
</template>
<script>
export default {
  name: '',
  // mixins: [],
  // components: {},
  // props: {},
  data() {
    return {};
  },
  // created () {},
  // mounted () {},
  // methods: {},
  // watch: {},
  // computed: {},
  // filters: {},
  // beforeDestroy () {},
};
</script>
<style scoped lang="less">
.aboutUsBox {
  min-height: 100vh;
  background: url('~@/assets/images/other/other1.png') no-repeat top;
  background-size: cover;
  .contentBox {
    padding: 0 58px;
    .intro1 {
      line-height: 1.6;
    }
  }
}
</style>
